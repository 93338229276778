import { ReactNode } from "react";
import { PartialProduct } from "@tides/base-library/dist/types";
import { buttonVariants } from "@tides/base-library/dist/components/ui/button";
interface Props {
  products: PartialProduct[];
  setHidden: (hide: boolean) => void;
}

export default function CartFooter({ products, setHidden }: Props): ReactNode {
  if (products.length === 0)
    return <h3 className="text-white text-small">No items in cart</h3>;

  //"block py-3 px-5 text-sm text-gray-100 bg-gray-700 rounded transition hover:bg-gray-600"
  return (
    <article id="cart-footer" className="flex flex-col gap-2.5 space-y-4 text-center">
      <a
        id="cart-checkout-button"
        href="cart"
        data-enhance-nav="false"
        className={buttonVariants({ variant: "default" })}
      >
        Go to cart
      </a>
      <a
        className={buttonVariants({ variant: "link", className: "hover:cursor-pointer" })}
        onClick={() => setHidden(true)}
      >
        Continue shopping
      </a>
    </article>
  );
}
