import { useState } from "react";
import {
  AddonFieldType,
  CartForm,
  IDictionary,
  PartialProduct,
  ProductAddon,
} from "@tides/base-library/dist/types";
import { IAddonResult, MultiAddonResult } from "@base-library/fields";
import fastJson from "fast-json-stable-stringify";

type Cart = {
  cartId?: string;
  selected_options: IDictionary<IAddonResult>;
  cartForms: CartForm[];
  products: PartialProduct[];
};

type Result = {
  type: ResultType;
  data: unknown;
};

enum ResultType {
  CartForms = 0,
  Products = 1,
  Empty = 3,
}

export default function useCart(cartId: string | undefined = undefined) {
  const [state, setState] = useState<Cart>({
    cartId: cartId,
    cartForms: [],
    products: [],
    selected_options: {},
  } as Cart);

  async function updateCart() {
    const cart = sessionStorage.getItem("cart") || "";

    if (!cart) return;

    const resp = await fetch(`/api/cart/${cart}`, {
      method: "GET",
    });

    if (resp.status >= 400) {
      if (cart) sessionStorage.removeItem("cart");

      return;
    }

    const result = (await resp.json()) as Result;

    if (result.type === ResultType.Empty) {
      sessionStorage.removeItem("cart");
      setState({ cartForms: [], products: [], selected_options: {} });
      return;
    }

    if (result.type === ResultType.CartForms) {
      setState({
        ...state,
        cartId: cart,
        cartForms: result.data as CartForm[],
      });
    } else {
      const res = result.data as IDictionary<PartialProduct[]>;
      setState({
        ...state,
        cartId: cart,
        products: Object.values(res).flatMap((x) => x),
      });
    }
  }

  async function addToCart(
    addToCartButton: HTMLElement,
    productSelection: HTMLElement | null
  ) {
    const cart = sessionStorage.getItem("cart") || "";
    const productId = addToCartButton.getAttribute("product-id")!;
    const childIndex = productSelection?.getAttribute("selected-child");

    const body = {
      product_slug: productId,
      cart_id: cart,
      child_index: childIndex,
      selected_addons: state.selected_options,
    };

    const resp = await fetch("/api/cart/", {
      method: "POST",
      body: fastJson(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (resp.status >= 400) return;

    const data = await resp.json();

    if (data.create_new_cart) {
      sessionStorage.setItem("cart", data.id);

      await updateCart();

      return;
    }

    sessionStorage.setItem("cart", data);

    setState((currentState) => ({
      ...currentState,
      cartId: data,
    }));

    updateCart();
  }

  function updateVariants(variant: ProductAddon, result: IAddonResult, remove: boolean) {
    const variants = state.selected_options;

    if (remove) {
      if (variant.field_type === AddonFieldType.Checkbox) {
        const checkBox = result as MultiAddonResult;
        if (checkBox.value.length > 0)
          variants[variant.id] = result;
        else
          delete variants[variant.id];
      } else {
        delete variants[variant.id];
      }
    }
    else variants[variant.id] = result;

    setState((old) => ({
      ...old,
      selected_options: variants,
    }));
  }

  return {
    products:
      state.cartForms.length > 0
        ? state.cartForms.map((x) => x.product)
        : state.products,
    addToCart,
    updateCart,
    updateVariants,
    selectedAddons: state.selected_options
  };
}

