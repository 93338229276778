import { useEffect, useState } from "react";
import { CartMessage, ProductAddon } from "@tides/base-library/dist/types";
import React from "react";
import CartItems from "./components/CartItems";
import CartFooter from "./components/CartFooter";
import useCart from "./hooks/useCart";
import { IAddonResult } from "@base-library/fields";
import { encode, decode, encodeURI } from "js-base64";

function App() {
  const cartChannel = new BroadcastChannel("cart-channel");

  const { products, addToCart, updateCart, updateVariants, selectedAddons } =
    useCart();

  const [hidden, setHidden] = useState<boolean>(true);

  let buyNowButton: HTMLElement | null;
  let productSelection: HTMLElement | null;
  let addToCartButton: HTMLElement | null;
  let productTitle: HTMLElement | null;

  const selectChildHandler = (e: Event) => {
    selectChild(e);
  };

  const addToCartHandler = async () => {
    await addToCart(addToCartButton!, productSelection);
  };

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      Blazor.addEventListener("enhancedload", onEnhancedLoad);
    }, 2000);

    onEnhancedLoad();

    cartChannel.onmessage = async (ev) => {
      if (ev.data === "update") {
        await updateCart();
        return;
      }

      onEnhancedLoad();

      const cartMessage = ev.data as CartMessage;
      const data = cartMessage.data as {
        price: number;
        result: IAddonResult;
        addon: ProductAddon;
      };
      if (cartMessage.type === "addVariant") {
        updateVariants(data.addon, data.result, false);
      } else if (cartMessage.type === "removeVariant") {
        updateVariants(data.addon, data.result, true);
      }

      const productId = addToCartButton!.getAttribute("product-id");
      if (buyNowButton instanceof HTMLAnchorElement) {
        buyNowButton.href = `buy/${productId}?selected_addons=${encodeURI(
          JSON.stringify(selectedAddons)
        )}`;
      }

      const priceElement = document.getElementById("product-price");

      priceElement!.textContent = `$${data.price}`;
    };

    return () => {
      // @ts-ignore
      Blazor.removeEventListener("enhancedload", onEnhancedLoad);

      cartChannel.close();

      if (addToCartButton)
        addToCartButton.removeEventListener("click", addToCartHandler);
      if (productSelection)
        productSelection.removeEventListener("change", selectChildHandler);
    };
  }, []);

  function onEnhancedLoad() {
    if (addToCartButton)
      addToCartButton.removeEventListener("click", addToCartHandler);
    if (productSelection)
      productSelection.removeEventListener("change", selectChildHandler);

    productSelection = document.getElementById("product-selection");
    addToCartButton = document.getElementById("product-add-to-cart-button");
    buyNowButton = document.getElementById("product-buy-button");
    productTitle = document.getElementById("product-title")!;

    if (addToCartButton) {
      if (productSelection)
        productSelection.addEventListener("change", selectChildHandler);

      addToCartButton.addEventListener("click", addToCartHandler);
    }

    updateCart().then();
  }

  function selectChild(event: Event) {
    if (!event.target) return;
    if (!(event.target instanceof HTMLSelectElement) || !productSelection)
      return;

    onEnhancedLoad();

    const index = event.target.options.selectedIndex;
    const option = event.target.options[index];

    if (productSelection.hasAttribute("bind-to-label")) {
      const attrValue = productSelection.getAttribute("bind-to-label")!;

      const label = document.getElementById(attrValue)!;

      productTitle!.textContent = option.value;
      label.textContent = `$${option.getAttribute("price")}`;
    }

    const childIndex = option.getAttribute("child-index")!;
    const productId = addToCartButton!.getAttribute("product-id");

    if (buyNowButton instanceof HTMLAnchorElement) {
      buyNowButton.href = `buy/${productId}/${childIndex || ""}`;
    }

    productSelection.setAttribute("selected-child", childIndex);
  }

  return (
    <React.Fragment>
      <button
        id="cart-button"
        className="relative right-4 rounded-md text-gray-300 hover:text-white"
        onClick={() => setHidden(!hidden)}
        aria-expanded="false"
      >
        <i className="bi bi-bag"></i>
        <span
          id="cart-amount"
          className="inline-block absolute right-0 top-1 left-1 bottom-auto z-10 py-1 px-1.5 font-bold leading-none text-center align-baseline whitespace-nowrap rounded-full scale-x-100 scale-y-100 rotate-0 translate-x-2/4 -translate-y-1/2 skew-x-0 skew-y-0 text-md"
        >
          {products.length}
        </span>
      </button>
      <article
        id="cart-modal"
        onBlur={() => setHidden(true)}
        onMouseLeave={() => setHidden(true)}
        className={`absolute z-[1000] float-left right-0 ${
          hidden ? "hidden" : ""
        } p-4 pt-4 sm:p-6 lg:p-8 w-screen max-w-sm list-none overflow-hidden rounded-lg border bg-background text-card-foreground shadow-sm text-left text-base shadow-lg`}
        tabIndex={-1}
        aria-labelledby="cart-button"
      >
        <section className="mt-6 space-y-6">
          <article
            id="cart-items"
            className="overflow-y-auto space-y-4 max-h-[250px]"
          >
            <CartItems products={products} />
          </article>

          <CartFooter products={products} setHidden={setHidden} />
        </section>
      </article>
    </React.Fragment>
  );
}

export default App;
