import { ReactNode } from "react";
import {
  AddonFieldType,
  IDictionary,
  PartialProduct,
  ProductAddon,
} from "@tides/base-library/dist/types";
import { IAddonResult, MultiAddonResult } from "@base-library/fields";

interface Props {
  products: PartialProduct[];
}

function calculateAddonCount(
  addons: ProductAddon[],
  selectedAddons: IDictionary<IAddonResult>
) {
  let count = Object.values(selectedAddons).length;

  for (const addonId of Object.keys(selectedAddons)) {
    const addon = addons.find((x) => x.id === addonId);
    if (!addon) continue;

    const result = selectedAddons[addonId];

    if (addon.field_type === AddonFieldType.Checkbox) {
      const checkboxResult = result as MultiAddonResult;

      count += checkboxResult.value.length - 1;
    }
  }

  return count;
}

export default function CartItems({ products }: Props): ReactNode {
  if (products.length === 0) return <></>;

  const group = products.reduce(
    (group: { [key: string]: PartialProduct[] }, item) => {
      if (!group[item.item_id]) group[item.item_id] = [];

      group[item.item_id].push(item);
      return group;
    },
    {}
  );

  return Object.values(group).map((item) => {
    const product = item[0];
    const addonLength = calculateAddonCount(
      product.addons!,
      product.selected_addons
    );

    return (
      <section key={product.item_id} className="flex gap-4 items-center">
        <img
          alt=""
          src={product.image_thumbnail}
          className="object-scale-down w-16 rounded lg:aspect-none"
        />
        <div>
          <h3 className="text-sm text-gray-300">{product.name}</h3>
          <dl className="mt-0.5 space-y-px text-xs text-gray-400">
            <div>
              <dt className="inline">${product.price * item.length} </dt>
              <dd className="inline">{item.length}x </dd>
            </div>
            {addonLength > 0 && (
              <dd className="inline">
                +{addonLength} addon
                {addonLength > 1 ? "s" : ""}
              </dd>
            )}
          </dl>
        </div>
      </section>
    );
  });
}
